export default ({ IDL }) => {
  return IDL.Service({
    'getAllTrials' : IDL.Func([], [IDL.Vec(IDL.Text)], ['query']),
    'getAllWinners' : IDL.Func([], [IDL.Vec(IDL.Text)], ['query']),
    'getNameOfTheWinner' : IDL.Func([], [IDL.Text], ['query']),
    'getNumberOfTrials' : IDL.Func([], [IDL.Nat], ['query']),
    'getNumberOfWinners' : IDL.Func([], [IDL.Nat], ['query']),
    'tryUnlock' : IDL.Func(
        [IDL.Text, IDL.Nat, IDL.Nat, IDL.Nat, IDL.Nat],
        [IDL.Text],
        [],
      ),
  });
};
export const init = ({ IDL }) => { return []; };