import { Actor, HttpAgent } from '@dfinity/agent';
import { idlFactory as locker_idl, canisterId as locker_id } from 'dfx-generated/locker';




const agent = new HttpAgent()
const locker = Actor.createActor(locker_idl, { agent, canisterId: locker_id });


const getValues = async () => {

  const nameWinner = await locker.getNameOfTheWinner();
  const numberWinners = await locker.getNumberOfWinners();
  const numberTrials = await locker.getNumberOfTrials();

  const divNumberTrials = document.querySelector(".numberOfTrial")
  const divNameWinner = document.querySelector(".nameOfFirstWinner")
  const divNumberWinners = document.querySelector(".numberOfWinners")

  divNumberTrials.innerText = "Number of trials : " + numberTrials
  divNumberWinners.innerText = "Number of winners : " + numberWinners
  divNameWinner.innerText = nameWinner

  return

}

const displayTrials = async () => {

  let listWinners = await locker.getAllWinners()
  let listTrials = await locker.getAllTrials()
  listTrials.reverse()

  if (listWinners.length > 10) {
    listWinners = listWinners.splice(0, 10)
  }
  if (listTrials.length > 10) {
    listTrials = listTrials.splice(0, 10)
  }

  const domListWinners = document.querySelector("#winners")
  const domListTrials = document.querySelector("#trials")


  listWinners.forEach((winner, index) => {
    let l = document.createElement('li')
    l.innerText = `${index + 1}. ` + winner
    domListWinners.append(l)
  });

  listTrials.forEach((trials) => {
    let l = document.createElement('li')
    l.innerText = trials
    domListTrials.append(l)
  });

  return;
}

async function handleTry() {
  document.getElementById("clickMeBtn").removeEventListener("click", handleTry)


  const name = document.querySelector('#name')
  const code1 = document.querySelector('#code1')
  const code2 = document.querySelector('#code2')
  const code3 = document.querySelector('#code3')
  const code4 = document.querySelector('#code4')

  if (confirm("Do you confirm ?")) {
    if ((name.value.trim() == "") || (code1.value.trim() == "") || (code2.value.trim() == "") || (code3.value.trim() == "") || (code4.value.trim() == "")) {
      alert("You must enter a valid value for all fields / Codes are natural numbers.")
      document.getElementById("clickMeBtn").addEventListener("click", handleTry)
      return;
    }
    if ((Number(code1.value) !== Math.round(code1.value)) || (Number(code2.value) !== Math.round(code2.value)) || (Number(code3.value) !== Math.round(code3.value)) || (Number(code4.value) !== Math.round(code4.value))) {
      alert("You must enter a valid value for all fields / Codes are natural numbers.")
      console.log("here")
      console.log(code1.value, Math.round(code1.value))
      console.log(code2.value, Math.round(code2.value))
      console.log(code3.value, Math.round(code3.value))
      console.log(code4.value, Math.round(code4.value))
      document.getElementById("clickMeBtn").addEventListener("click", handleTry)
      return;
    }

    else {
      const message = document.querySelector(".message")
      message.innerText = "Try opening the locker...  "
      const tryOpen = async () => {
        const answer = await locker.tryUnlock(name.value.trim(), Number(code1.value.trim()), Number(code2.value.trim()), Number(code3.value.trim()), Number(code4.value.trim()));
        message.innerText = answer;
        return;
      }
      await tryOpen()
      document.getElementById("clickMeBtn").addEventListener("click", handleTry)
      return;
    }
  }
}



document.getElementById("clickMeBtn").addEventListener("click", handleTry)

getValues();
displayTrials();


